document.addEventListener("DOMContentLoaded", function(event) {
    var enhancedPlayers = document.querySelectorAll('.enhanced-video.tn-mediaplayer'),
        canAdRefresh = false,
        adRefreshDelay = 2000,
        enableAdRefresh = function() {
            canAdRefresh = true;
        };

    if (__tnt.ads) {
        setTimeout(enableAdRefresh, adRefreshDelay);
    }

    /** Enhanced player function */
    var enhanceVideoSlideshow = function(player, slideshow) {
        if (player && slideshow) {
            var slideshowPlaylistItems = slideshow.getElementsByTagName('a'),
                slideshowNowShowing = slideshow.querySelector('.tnt-slider-item .tn-playlist-item'),
                region = player.querySelector('.tn-media-ad .tncms-region'),
                regionHasContent = false,
                refreshableAds = [];

            if (region && region.hasChildNodes()) {
                var blocks = region.children;
                for (var i = 0; i < blocks.length; i++) {
                    var adBlock = blocks[i].querySelector('.tnt-ads');
                    if (adBlock) {
                        if (/ad-\d+/.test(adBlock.id)) {
                            refreshableAds.push(adBlock.id);
                        }
                        regionHasContent = true;

                    } else if (blocks[i].firstElementChild && blocks[i].firstElementChild.offsetParent !== null) {
                        regionHasContent = true;
                    }
                }
            }

            if (!regionHasContent) {
                var ad = player.querySelector('.tn-media-ad');
                if (ad) {
                    ad.parentNode.removeChild(player.querySelector('.tn-media-ad'));
                }
                player.querySelector('.tn-mediaplayer-widget').classList.add('tn-mediaplayer-no-companion');
            }

            if (slideshowNowShowing) {
                slideshowNowShowing.classList.add('now-showing');
            }

            /* Playlist playlist item setup */
            if (slideshowPlaylistItems && slideshowPlaylistItems.length) {
                
                /* Observe for modal or photo purchase */
                var observer = new MutationObserver(overlayControl),
                    observerOptions = { attributeFilter: ['class'] };
                function overlayControl() {
                    if (__tnt.video.advanceTimer && __tnt.elementOnScreen('.tn-mediaplayer.enhanced-video')) {
                        if ( document.body.classList.contains('has-offcanvas--overlay')
                                || document.body.classList.contains('modal-open') ) {
                            __tnt.video.advanceTimer.pause();
                        } else {
                            __tnt.video.advanceTimer.resume();
                        
                        }
                    }
                }
                observer.observe(document.body, observerOptions);
                
                for (var spi = 0; spi < slideshowPlaylistItems.length; spi++) {
                    slideshowPlaylistItems[spi].addEventListener('click', function(ev) {
                        ev.preventDefault();
                        
                        // Return if clicked item is active
                        if (ev.target.closest('.tn-playlist-item').classList.contains('now-showing')) {
                            return;
                        }

                        if (canAdRefresh && __tnt.ads && __tnt.ads.refreshPos && refreshableAds.length > 0) {
                            for (var i = 0; i < refreshableAds.length; i++) {
                                __tnt.ads.refreshPos(refreshableAds[i]);
                            }
                            canAdRefresh = false;
                            setTimeout(enableAdRefresh, adRefreshDelay);
                        }

                        var playlistEl = ev.target.closest('.tn-playlist-item');
                        if (!player) {
                            var player = playlistEl.closest('.tn-mediaplayer');
                        }
                        
                        if (__tnt.video.advanceTimer) {
                            __tnt.video.advanceTimer.cancel();
                        }
                        
                        mediaUnload.playlistEl = playlistEl;
                        mediaLoad.playlistEl = playlistEl;
                        player.dispatchEvent(mediaUnload);
                        player.dispatchEvent(mediaLoad);
                    });
                }

                // Initiatiate auto-play if not in the admin
                if (player.dataset.autoPlay && player.dataset.autoPlay == 'true' && player.dataset.admin && player.dataset.admin != 'true') {
                    setTimeout(function() {
                        var playlistEl = slideshowPlaylistItems[0].closest('.tn-playlist-item');
                        mediaUnload.playlistEl = playlistEl;
                        mediaLoad.playlistEl = playlistEl;
                        player.dispatchEvent(mediaUnload);
                        player.dispatchEvent(mediaLoad);
                    }, 500);
                } else {
                    /** Reveal full non audio/video content */
                    var playlistEl = slideshowPlaylistItems[0].closest('.tn-playlist-item');
                    if (playlistEl.dataset.type != ('video') && playlistEl.dataset.type != ('audio')) {
                        mediaUnload.playlistEl = playlistEl;
                        mediaLoad.playlistEl = playlistEl;
                        player.dispatchEvent(mediaUnload);
                        player.dispatchEvent(mediaLoad);
                    }
                }
            }

            player.addEventListener('tntMediaUnload', function(event) {
                var nowShowing = slideshow.querySelector('.now-showing');
                if (nowShowing) {
                    slideshow.querySelector('.now-showing').classList.remove('now-showing');
                }
            });

            player.addEventListener('tntMediaLoaded', function(event) {
                var playlistItem = event.playlistEl.parentElement,
                    playlistItemUuid = null,
                    slideshowItem = event.playlistEl,
                    slideshowItemNum = null;

                if (event.playlistEl.hasAttribute('data-uuid')) {
                    playlistItemUuid = event.playlistEl.getAttribute('data-uuid');
                }

                if (playlistItemUuid != null) {
                    slideshowItem = slideshow.querySelector('.tn-playlist-item[data-uuid="' + playlistItemUuid + '"]');
                    if (slideshowItem) {
                        playlistItem = slideshowItem.parentElement;
                    }
                }

                if (playlistItem && playlistItem.hasAttribute('data-item')) {
                    slideshowItemNum = playlistItem.getAttribute('data-item')
                }

                if (slideshowItem) {
                    slideshowItem.classList.add('now-showing')
                }

                if (slideshowItemNum != null) {
                    __tnt.slider.sliderApp.slideTo(slideshow, slideshowItemNum)
                }
            });
            
            if (player.querySelector('.enhanced-playlist-slideshow')) {
                var slideshowWrapper = player.querySelector('.enhanced-playlist-slideshow');

                /* Listeners to prevent playlist advance while interacting */
                slideshowWrapper.addEventListener('mouseover', function() {
                    // Cancel advance timer
                    if (__tnt.video.advanceTimer) {
                        __tnt.video.advanceTimer.cancel();
                    }
                });

                slideshowWrapper.addEventListener('touchstart', function() {
                    // Cancel advance timer mobile
                    if (__tnt.video.advanceTimer) {
                        __tnt.video.advanceTimer.cancel();
                    }

                }, { passive: true });

                slideshowWrapper.addEventListener('mouseleave', function() {
                    // Resume advance timer
                    if (__tnt.video.advanceTimer) {
                        __tnt.video.advanceTimer.resume();
                    }
                });

                slideshowWrapper.addEventListener('touchend', function() {
                    // Resume advance timer mobile
                    if (__tnt.video.advanceTimer) {
                        __tnt.video.advanceTimer.resume();
                    }
                }, { passive: true });
            }
            
            // Collapsible description
            if (player.querySelector('.caption-toggle')) {
                player.querySelector('.caption-toggle').addEventListener('click', function() {
                    player.querySelector('.toggle-text-show').classList.toggle('hidden');
                    player.querySelector('.toggle-text-hide').classList.toggle('hidden');
                    player.querySelector('.tn-mediaplayer-description').classList.toggle('collapsed');
                });
            }
        }
    }

    /** Initialize players */
    if (enhancedPlayers && enhancedPlayers.length) {
        for (var ep = 0; ep < enhancedPlayers.length; ep++) {
            var player = enhancedPlayers[ep],
                slideshowWrapper = null,
                slideshowTemplate = null,
                slideshow = null;

            if (player.querySelector('.tnt-slider-template')) {
                slideshowTemplate = player.querySelector('.tnt-slider-template');
            }

            if (slideshowWrapper && slideshowWrapper.querySelector('.tnt-slider')) {
                slideshow = slideshowWrapper.querySelector('.tnt-slider');
                enhanceVideoSlideshow(player, slideshow);
            } else if (slideshowTemplate) {
                if (slideshowTemplate.classList.contains('tnt-slider-ready')) {
                    var player = slideshowTemplate.closest('.enhanced-video.tn-mediaplayer'),
                        slideshow = player.querySelector('.tnt-slider');
                    enhanceVideoSlideshow(player, slideshow);
                } else {
                    slideshowTemplate.addEventListener('tntSliderReady', function(ev) {
                        var player = ev.target.closest('.enhanced-video.tn-mediaplayer'),
                            slideshow = player.querySelector('.tnt-slider');   
                        enhanceVideoSlideshow(player, slideshow);
                    });
                }
            }
        } // end foreach loop
    } // end conditional wrapper
});